import './App.css';
import { Reno } from './Components/RenoComponent/RenoComponent';
import { ProfilerComponent } from './Components/ProfilerComponent/ProfilerComponent';
import { ScrapperComponent } from './Components/scrapperComponent/ScrapperComponent';
import IPInfo from 'ip-info-react';

function App() {
  return (
    <IPInfo>
      <div className="App">
        <div className="nihil">
          <div className="poe-container">
            <h2>there was nothing..</h2>
            <Reno />
          </div>

          <div className="context-container">
            <ProfilerComponent />
          </div>

          <div className="text-container">
            <ScrapperComponent />
          </div>
        </div>
      </div>
    </IPInfo>
  );
}

export default App;
