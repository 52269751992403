import React, { useState } from 'react';

export const ScrapperComponent = () => {
    const [url, setUrl] = useState('');
    const [results, setResults] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const scrapeData = async () => {
        console.log('..')
        setError(null); 
        setIsLoading(true); 

        try {
            const response = await fetch('http://localhost:3001/scrape', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ url }),
            });
            console.log()

            if (!response.ok) {
                throw new Error(`Failed to scrape: ${response.statusText}`);
            }

            const data = await response.json();
            setResults(data.results || []);
        } catch (err) {
            setError(err.message || 'An unexpected error occurred');
        } finally {
            setIsLoading(false); 
        }
    };

    return (
        <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
            <h1>Web Scraper</h1>
            <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="url.. todo esto es temporal."
                style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
            />
            <button
                onClick={scrapeData}
                style={{
                    padding: '10px 20px',
                    backgroundColor: '#007BFF',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                }}
                disabled={isLoading || !url}
            >
                {isLoading ? 'wait wait...' : 'oka'}
            </button>
            {error && <p style={{ color: 'red', marginTop: '10px' }}>Error: {error}</p>}
            <div style={{ marginTop: '20px' }}>
                <h2>Scraped Results</h2>
                <ul style={{ listStyle: 'none', padding: '0' }}>
                    {results.length === 0 && !isLoading && <p>No results found.</p>}
                    {results.map((category, categoryIndex) => (
                        <li key={categoryIndex} style={{ marginBottom: '20px' }}>
                            <h3>Category {categoryIndex + 1}</h3>
                            <ul style={{ listStyle: 'none', padding: '0' }}>
                                {category.articles.map((article, articleIndex) => (
                                    <li key={articleIndex}>
                                        <strong>{article.title}</strong>
                                        <p>{article.story}</p>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
