import React, { useState,useEffect } from "react";
import "./Reno.css";

export const Reno = () => {
    const [rightEyeState, setRightEyeState] = useState("open");
    const [leftEyeState, setLeftEyeState] = useState("open");

    const eyeStates = {
        right: {
            open: 'M1396.16 1096.5C1588.27 1046.57 1663.29 1115.01 1663.29 1115.01C1596.81 1164.71 1556.29 1231.26 1461 1300.01C1365.71 1368.76 1275.29 1364.71 1104.91 1365.01C1084.28 1365.81 1317.52 1112.94 1396.16 1096.5Z',
            closed: 'M1427.5 1315C1460.5 1308 1663.29 1115.01 1663.29 1115.01C1596.81 1164.71 1556.29 1231.26 1461 1300.01C1365.71 1368.76 1074 1367.5 1104.91 1365.01C1175 1359.37 1287.5 1365.5 1427.5 1315Z'
        },
        left: {
            open: 'M552.882 1057.61L294.339 841.25C293.775 840.778 292.932 841.007 292.77 841.725C289.827 854.804 294.339 946.003 323.609 1068.35C376.495 1328.28 659.522 1328.91 659.132 1328.28C658.742 1327.65 659.628 1329.08 659.132 1328.28C634.619 1262.25 610.419 1105.76 552.882 1057.61Z',
            closed: 'M352.882 1157.61L294.339 841.21C793.775 840.778 292.932 841.007 292.77 841.725C289.827 824.804 294.339 646.003 326.609 1068.25C376.495 1328.28 651.522 1338.91 659.132 1328.28C658.742 1527.65 659.628 1329.08 659.132 1328.28C604 1328.28 400 1297 340.5 1123.5Z',
            // closed: 'M552.882 1057.61L294.339 841.25C293.775 840.778 292.932 841.007 292.77 841.725C289.827 854.804 294.339 946.003 323.609 1068.25C176.495 1328.28 651.522 1338.91 659.132 1328.28C658.742 1527.65 659.628 1329.08 659.132 1328.28C604 1328.28 400 1297 340.5 1123.5Z',
            // closed: 'M340.5 1123.5C285 952 294.339 841.25 294.339  840.778 292.932 841.007 292.77 841.725C289.827 854.804 294.339 946.003 323.609 1068.35C376.495 1328.28 659.522 1328.91 659.132 1328.28C658.742 1327.65 659.628 1329.08 659.132 1328.28C604 1328.28 400 1297 340.5 1123.5Z'
        }
    };

    const toggleRightEye = () => {
        setRightEyeState(prevState => (prevState === "open" ? "closed" : "open"));
    };

    const toggleLeftEye = () => {
        setLeftEyeState(prevState => (prevState === "open" ? "closed" : "open"));
    };


    useEffect(() => {
        const blinkInterval = setInterval(() => {
            setRightEyeState("closed");
            setLeftEyeState("closed");

            setTimeout(() => {
                setRightEyeState("open");
                setLeftEyeState("open");
            }, 300);
        }, 5000);

        return () => clearInterval(blinkInterval);
    }, []);

    return (
        <div className="catainer">
            <svg id="cat" viewBox="0 0 2001 1995" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="left-face" fillRule="evenodd" clipRule="evenodd" d="M34.6421 803.809C34.6159 803.93 34.6072 804.054 34.6264 804.176L104.689 1248.12C104.702 1248.2 104.735 1248.28 104.767 1248.35C200.532 1474.79 598.846 1741.67 599.455 1742.08C599.473 1742.09 599.452 1742.09 599.47 1742.1L921.263 1990.68C922.135 1991.36 923.296 1990.33 922.736 1989.38L692.533 1599.71C692.5 1599.57 865.51 1325.36 865.541 1325.21L951.369 866.856C951.392 866.749 951.397 866.639 951.384 866.53L919.572 593.87L664.912 495.955L2.47019 150.12C1.6799 149.707 0.780179 150.457 1.04831 151.307L104.642 479.757C104.694 479.923 104.694 480.099 104.658 480.269L34.6421 803.809Z" fill="#C3D8FF"/>
            <path className="right-face" fillRule="evenodd" clipRule="evenodd" d="M1886.14 1349.37L2000.4 770.565L1916.89 380.12L1856.42 1.84313C1856.3 1.07932 1855.39 0.737454 1854.79 1.23009L1274.88 480.3L919.023 594.16L950.842 866.82C950.855 866.929 950.85 867.039 950.827 867.146L865 1325.5C864.969 1325.64 691.967 1599.86 692 1600L924.407 1993.42C924.657 1993.84 924.858 1994.15 925.315 1993.97C941.97 1987.4 1392.92 1809.65 1511.54 1763.44C1592.88 1731.74 1666.06 1685.23 1726.53 1626.78C1806.72 1549.28 1861.9 1453.36 1886.14 1349.37Z" fill="black" stroke="black"/>
            <path className="right-ear" d="M1346 548.775L1609.62 744.606C1609.89 744.803 1610.24 744.856 1610.55 744.747L1857.61 698.611L1813.12 354.003L1803.46 102.966C1803.42 102.099 1802.38 101.683 1801.76 102.29L1346 548.775Z" fill="#C3D8FF"/>
            <path className="left-ear" d="M75.5135 217.147C74.7038 216.667 73.7394 217.469 74.0648 218.353L161.818 456.573C161.879 456.737 161.895 456.915 161.865 457.088L117.479 715.053C117.381 715.621 117.783 716.154 118.356 716.216L427.562 749.887L542.528 725.989C542.73 725.947 542.915 725.843 543.056 725.692L680.968 577.8C681.403 577.334 681.296 576.584 680.747 576.258L75.5135 217.147Z" fill="black"/>
            
                <path
                    className="left-eye"
                    d={eyeStates.left[leftEyeState]}
                    fill="black"
                    onClick={toggleLeftEye} 
                    style={{ cursor: "pointer" }} 
                />
                <path
                    className="right-eye"
                    d={eyeStates.right[rightEyeState]}
                    fill="#C3D8FF"
                    onClick={toggleRightEye} 
                    style={{ cursor: "pointer" }} 
                />
            </svg>
        </div>
    );
};
